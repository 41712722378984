import React, { useEffect } from 'react';
import Layout from '../../../components/Layout';

function Index(props) {
    useEffect(()=>{
        window.location.replace = '/'
    },[])
    return (
        <div>
            <Layout >
            <section className="cw-bg-greengradient text-center py-10 px-5">
                <div className="text-center">
                    <h1 className="text-5xl text-white font-bold pb-2">Refer an Agent</h1>
                    <div className='flex justify-center pt-5'>
                        {/* <iframe title="CWA-MS Partner Program Video" src="https://player.vimeo.com/video/125490748?h=014457da2a&amp;dnt=1&amp;app_id=122963" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen=""></iframe> */}
                    </div>
                </div>
            </section>
            <section>
                <div className='bg-white mx-auto section py-10 text-center w-1/2'>
                    <p className='text-lg'>CWA offers the most unique Agent Referral Program in the industry. Know someone who will make a great sales agent for CWA?
                    Refer them below and receive monthly residual payments for each merchant they sign. This will not effect their compensation, but we will pay you 
                    every month and all you have to do is fill out this form!</p>
                </div>
                <div className='bg-gray-100 py-10'>
                    <div className='mx-auto section w-1/2'>
                        <form action='/' method="POST" enctype="multipart/form-data">
                            <div id="referralForm" className="grid grid-cols-2 gap-3">
                                <div className="flex flex-col col-span-2">
                                <label className="font-medium text-sm text-gray-900" for="name">Name</label>
                                <input required autocomplete="nah" type="text" name="name" id="name" placeholder="e.g. John Appleseed"
                                    className=" w-100 mt-2 py-2 px-3 rounded-lg dark:bg-gray-800 border border-gray-300 bg-white focus:bg-white dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"/>
                                </div>
                                <div className="flex flex-col col-span-2">
                                <label className="font-medium text-sm text-gray-900" for="email">Email</label>
                                <input required autocomplete="nah" type="text"  name="email" id="email" placeholder=""
                                    className=" w-100 mt-2 py-2 px-3 rounded-lg dark:bg-gray-800 border border-gray-300 bg-white focus:bg-white dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"/>
                                </div>
                                <div className="flex flex-col col-span-2">
                                <label className="font-medium text-sm text-gray-900" for="phone">Phone</label>
                                <input required autocomplete="nah" type="text" name="phone" id="phone" placeholder="e.g. (215) 555 5555"
                                    className=" w-100 mt-2 py-2 px-3 rounded-lg dark:bg-gray-800 border border-gray-300 bg-white focus:bg-white dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"/>
                                </div>
                                <div className="flex flex-col col-span-2">
                                <label className="font-medium text-sm text-gray-900" for="company">Tell us about yourself</label>
                                <textarea required autocomplete="nah" type="text" name="company" id="company" placeholder="I want to be a CWA Partner because..." rows={5}
                                    className=" w-100 mt-2 py-2 px-3 rounded-lg dark:bg-gray-800 border border-gray-300 bg-white focus:bg-white dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none">
                                </textarea>
                                </div>
                                <div className="col-span-2 flex justify-start space-x-3">
                                    <button id="submit-button" type='submit'
                                        className="flex cw-bg-lightblue px-3 py-2 mt-4 mb-3 rounded text-white font-semibold">
                                        <span id="submit-text">Submit Form</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                
                </div>
            </section>

            </Layout>
        </div>
    );
}

export default Index;